import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-class-progression"></a><h2>Epic Class Progression
    </h2>
    <table border="0" cellPadding="0" cellSpacing="0">
      <tbody>
        <tr>
          <td style={{
            "width": "20%"
          }}>
            <a style={{
              "color": "#579eb6"
            }} href="#barbarian">Barbarian</a>
            <a style={{
              "color": "#579eb6"
            }} href="#bard">Bard</a>
            <a style={{
              "color": "#579eb6"
            }} href="#cleric">Cleric</a>
            <a style={{
              "color": "#579eb6"
            }} href="#druid">Druid</a>
          </td>
          <td style={{
            "width": "20%"
          }}>
            <a style={{
              "color": "#579eb6"
            }} href="#fighter">Fighter</a>
            <a style={{
              "color": "#579eb6"
            }} href="#monk">Monk</a>
            <a style={{
              "color": "#579eb6"
            }} href="#paladin">Paladin</a>
            <a style={{
              "color": "#579eb6"
            }} href="#ranger">Ranger</a>
          </td>
          <td style={{
            "width": "20%"
          }} valign="top">
            <a style={{
              "color": "#579eb6"
            }} href="#rogue">Rogue</a>
            <a style={{
              "color": "#579eb6"
            }} href="#sorcerer">Sorcerer</a>
            <a style={{
              "color": "#579eb6"
            }} href="#wizard">Wizard</a>
          </td>
          <td style={{
            "width": "20%"
          }}>
            <a style={{
              "color": "#579eb6"
            }} href="#psion">Psion</a>
            <a style={{
              "color": "#579eb6"
            }} href="#psychic-warrior">Psychic Warrior</a>
            <a style={{
              "color": "#579eb6"
            }} href="#souldknife">Soulknife</a>
            <a style={{
              "color": "#579eb6"
            }} href="#wilder">Wilder</a>
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-barbarian"></a><h3><a id="barbarian"></a>EPIC BARBARIAN</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44273_C1_barbarian.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d12</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 4 + Int modifier </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Barbarian Rage:</span> The
epic barbarian gains one use of rage per day every four levels after
20th. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Trap Sense (Ex):</span> The
epic barbarian&#8217;s bonus increases by +1 every three levels higher than
18th. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Damage Reduction (Ex):</span>
The epic barbarian&#8217;s damage reduction increases by 1 point every three
levels higher than 19th. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
barbarian gains a bonus feat (selected from the list of epic barbarian
bonus feats) every four levels higher than 20th. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Barbarian Bonus Feat
List:</span> Armor Skin, Chaotic Rage, Damage Reduction, Devastating
Critical, Dire Charge, Epic Endurance, Epic Prowess, Epic Speed, Epic
Toughness, Epic Weapon Focus, Fast Healing, Incite Rage, Legendary
Climber, Legendary Leaper, Legendary Rider, Legendary Tracker,
Legendary Wrestler, Mighty Rage, Overwhelming Critical, Ruinous Rage,
Terrifying Rage, Thundering Rage. </p>
    <a id="table-the-epic-barbarian"></a><p><b>Table: THE EPIC BARBARIAN</b></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Barbarian
Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>21st</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Trap
sense +7
          </td>
        </tr>
        <tr>
          <td>22nd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Damage
reduction 6/-
          </td>
        </tr>
        <tr className="odd-row">
          <td>23rd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>24th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Rage
7/day, Trap sense +8, bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>25th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Damage
reduction 7/-
          </td>
        </tr>
        <tr>
          <td>26th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>27th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Trap
sense +9
          </td>
        </tr>
        <tr>
          <td>28th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Damage
reduction 8/-, Rage 8/day, bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>29th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td className="last-row">30th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Trap sense +10
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-bard"></a><h3><a id="bard"></a>EPIC BARD</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44199_C1_bard.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d6</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 6 + Int modifier</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells:</span> The bard&#8217;s
caster level is equal to his or her class level. The bard&#8217;s number of
spells per day does not increase after 20th level. The bard does not
learn additional spells unless he or she selects the Spell Knowledge
feat.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bardic Music:</span> The bard
gains no new bardic music effects from his or her Perform ranks.
However, he or she may select epic feats that grant new bardic music
effects</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bardic Knowledge:</span> Add
the bard&#8217;s class level + Intelligence modifier to all bardic knowledge
checks, as normal. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
bard gains a bonus feat (selected from the list of epic bard bonus
feats) every three levels after 20th. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Bard Bonus Feat List:</span>
Augmented Alchemy, Deafening Song, Epic Inspiration, Epic Leadership,
Epic Reputation, Epic Skill Focus, Group Inspiration, Hindering Song,
Improved Combat Casting, Improved Metamagic, Improved Spell Capacity,
Inspire Excellence, Lasting Inspiration, Master Staff, Master Wand,
Music of the Gods, Permanent Emanation, Polyglot, Ranged Inspiration,
Rapid Inspiration, Reactive Countersong, Spell Knowledge, Spell
Stowaway, Spell Opportunity, Tenacious Magic. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Inspire Courage (Su):</span>
The epic bard&#8217;s bonus when this ability is used increases by +1 every
six levels higher than 20th.</p>
    <a id="table-the-epic-bard"></a><p><b>Table: THE EPIC BARD</b></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Bard
Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>21st</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>22nd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>23rd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td>24th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>25th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>26th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Inspire
courage +5, Bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>27th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>28th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>29th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td className="last-row">30th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-cleric"></a><h3><a id="cleric"></a>EPIC CLERIC</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44277_C1_cleric.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d8</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 2 + Int modifier</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells:</span> The cleric&#8217;s
caster level is equal to his or her class level. The cleric&#8217;s number of
spells per day does not increase after 20th level</p>
    <p>Turn or Rebuke Undead: Use the cleric&#8217;s class level to
determine the most powerful undead affected by a turn or rebuke check
and the turning damage, just as normal. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
cleric gains a bonus feat (selected from the list of epic cleric bonus
feats) every three levels after 20th. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Cleric Bonus Feat List:</span>
Armor Skin, Automatic Quicken Spell, Automatic Silent Spell, Automatic
Still Spell, Bonus Domain, Enhance Spell, Epic Spell Focus, Epic Spell
Penetration, Epic Spellcasting, Ignore Material Components, Improved
Alignment-Based Casting, Improved Combat Casting, Improved Heighten
Spell, Improved Metamagic, Improved Spell Capacity, Intensify Spell,
Multispell, Negative Energy Burst, Permanent Emanation, Planar Turning,
Positive Energy Aura, Spectral Strike, Spell Stowaway, Spell
Opportunity, Spontaneous Domain Access, Spontaneous Spell, Tenacious
Magic, Undead Mastery, Zone of Animation. </p>
    <a id="table-the-epic-cleric"></a><span style={{
      "fontWeight": "bold"
    }}>Table: THE EPIC CLERIC
    </span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Cleric
Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>21st</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>22nd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>23rd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td>24th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>25th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>26th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>27th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>28th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>29th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td className="last-row">30th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-druid"></a><h3><a id="druid"></a>EPIC DRUID</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44206_C1_druid.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d8</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 4 + Int modifier</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells:</span> The druid&#8217;s
caster level is equal to his or her class level. The druid&#8217;s number of
spells per day does not increase after 20th level. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Animal Companion:</span> The
Druid&#8217;s animal companion continues to increase in power.&nbsp; Every
three levels higher than 20th the animal companion gains 2 bonus hit
dice, +1 strength and dexterity, and an additional trick. </p>
    <p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Wild
Shape:</span> The epic druid can use this ability to take the form of
an animal one additional time per day every four levels higher than
18th.&nbsp; The druid&#8217;s ability to wild shape into an elemental does
not improve.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
druid gains a bonus feat (selected from the list of epic druid bonus
feats) every four levels after 20th. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Druid Bonus Feat List:</span>
Automatic Quicken Spell, Automatic Silent Spell, Automatic Still Spell,
Colossal Wild Shape, Diminutive Wild
Shape, Dragon Wild Shape, Energy Resistance, Enhance Spell, Epic Spell
Focus, Epic Spell Penetration, Epic Spellcasting, Fast Healing, Fine
Wild Shape, Gargantuan Wild Shape, Ignore Material Components, Improved
Combat Casting, Improved Elemental Wild Shape, Improved Heighten Spell,
Improved Metamagic, Improved Spell Capacity, Intensify Spell, Magical
Beast Companion, Magical
Beast WildShape, Multi-spell, Perfect Health, Permanent Emanation,
Plant
Wild Shape, Spell Stowaway, Spell Opportunity, Spontaneous Spell,
Tenacious
Magic, Vermin Wild Shape. </p>
    <a id="table-the-epic-druid"></a><span style={{
      "fontWeight": "bold"
    }}>Table: THE EPIC DRUID</span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Druid
Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>21st</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>22nd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Wild shape</span> 7/day
          </td>
        </tr>
        <tr className="odd-row">
          <td>23rd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>24th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>25th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>26th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Wild shape</span> 8/day
          </td>
        </tr>
        <tr className="odd-row">
          <td>27th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>28th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>29th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td className="last-row">30th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Wild shape</span>
9/day
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-fighter"></a><h3><a id="fighter"></a>EPIC FIGHTER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44248_C1_fighter.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d10</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 2 + Int modifier</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
fighter gains a bonus feat (selected from the list of epic fighter
bonus feats) every two levels after 20th. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Fighter Bonus Feat List:</span>
Armor Skin, Combat Archery, Damage Reduction, Devastating Critical,
Dire Charge, Distant Shot, Energy Resistance, Epic Endurance, Epic
Leadership, Epic Prowess, Epic Toughness, Epic Weapon Focus, Epic
Weapon Specialization, Exceptional Deflection, Improved Combat
Reflexes, Improved Manyshot, Improved Stunning Fist, Improved Whirlwind
Attack, Infinite Deflection, Instant Reload, Legendary Commander,
Legendary Rider, Legendary Wrestler, Overwhelming Critical, Penetrate
Damage Reduction, Perfect Two-Weapon Fighting, Reflect Arrows,
Spellcasting Harrier, Storm of Throws, Superior Initiative, Swarm of
Arrows, Two-Weapon Rend, Uncanny Accuracy. In addition to the feats on
this list, the fighter may treat any feat designated as a fighter bonus
feat, but not listed here, as being on his or her bonus feat list. </p>
    <a id="table-the-epic-fighter"></a><span style={{
      "fontWeight": "bold"
    }}>Table: THE EPIC FIGHTER</span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Fighter
Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>21st</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>22nd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>23rd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>.
          </td>
        </tr>
        <tr>
          <td>24th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>25th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>26th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>27th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>28th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>29th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td className="last-row">30th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-monk"></a><h3><a id="monk"></a>EPIC MONK</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44290_C1_monk.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d8</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 4 + Int modifier</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>AC Bonus:</span> The monk&#8217;s
bonus to Armor Class when unarmored increases by +1 every five levels
higher than 20th. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Unarmed Strike:</span> The
damage for a monk&#8217;s unarmed strike does not increase after 20th level. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Stunning Attack:</span> If
the monk has the Stunning Fist feat use the monk&#8217;s class level when
determining the DC to resist this attack, as normal. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Unarmored Speed Bonus:</span>
The epic monk�s by 10 feet at
21st level and every three levels thereafter (24th, 27th,
30th, and so on). The unarmored speed of Small and
dwarven monks increases by 5 feet instead of 10 feet.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Ki Strike:</span> The monk&#8217;s
ki strike ability does not automatically increase with class level
after 16th level. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Wholeness of Body (Su):</span>
The epic monk can cure up to twice his or her class level in hit points
each day, as normal. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Abundant Step (Su):</span>
Use the monk&#8217;s class level when determining the effective caster level
of this ability, as normal. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Diamond Soul (Ex):</span> The
epic monk&#8217;s spell resistance is equal to his or her class level +10, as
normal. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Quivering Palm (Su):</span>
Use the monk&#8217;s class level when determining the DC to resist this
attack, as normal. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Empty Body (Su):</span> Use
the monk&#8217;s class level when determining the duration of this effect, as
normal. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
monk gains a bonus feat (selected from the list of epic monk bonus
feats) every five levels higher than 20th. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Monk Bonus Feat List:</span>
Armor Skin, Blinding Speed, Damage Reduction, Energy Resistance, Epic
Prowess, Epic Speed, Epic Toughness, Exceptional Deflection, Fast
Healing, Improved Combat Reflexes, Improved Ki Strike, Improved Spell
Resistance, Improved Stunning Fist, Infinite Deflection, Keen Strike,
Legendary Climber, Legendary Wrestler, Reflect Arrows, Righteous
Strike, Self-Concealment, Shattering Strike, Vorpal Strike. </p>
    <a id="table-the-epic-monk"></a><span style={{
      "fontWeight": "bold"
    }}>Table: THE EPIC MONK
    </span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Monk
Level</th>
          <td style={{
            "verticalAlign": "middle",
            "width": "100px"
          }}><span style={{
              "fontWeight": "bold"
            }}>AC Bonus</span>
          </td>
          <th colSpan="1" style={{
            "width": "150px"
          }}>Unarmored Speed Bonus
          </th>
          <td style={{
            "verticalAlign": "middle",
            "width": "100px"
          }}><span style={{
              "fontWeight": "bold"
            }}>Special</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>21st</td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+4
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "150px"
          }}>+70 ft.
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>22nd</td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+4
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "150px"
          }}>+70ft.
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>23rd</td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+4
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "150px"
          }}>+70 ft.
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>24th</td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+4
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "150px"
          }}>+80 ft.
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>25th</td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+5
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "150px"
          }}>+80 ft.
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td>26th</td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+5
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "150px"
          }}>+80 ft.
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>27th</td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+5
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "150px"
          }}>+90 ft.
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>28th</td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+5
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "150px"
          }}>+90 ft.
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>29th</td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+5
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "150px"
          }}>+90 ft.
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>-
          </td>
        </tr>
        <tr>
          <td className="last-row">30th</td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+6
          </td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "150px"
          }}>+100 ft.
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>Bonus feat
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-paladin"></a><h3><a id="paladin"></a>EPIC PALADIN</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44263_C1_paladin.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d10</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 2 + Int modifier</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Lay on Hands (Su):</span>
Each day a paladin can cure a total number of hit points equal to his
or her Charisma bonus (if any) times his or her class level, as normal</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Smite Evil (Su):</span> The
epic paladin adds his or her class level to damage with any smite evil
attack, as normal. He or she can smite one additional time per day for
every five levels higher than 20th.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Turn Undead:</span> The
paladin turns undead as a cleric of two levels lower, as normal. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells:</span> The paladin&#8217;s
caster level is equal to one-half his or her class level, as
normal.&nbsp; The paladin&#8217;s number of spells per day does not increase
after 20th level. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special Mount:</span> The
epic paladin&#8217;s special mount continues to increase in power. Every five
levels after 20th the special mount gains +2 bonus Hit Dice, its
natural armor increases by +2, its Strength adjustment increases by +1,
and its Intelligence increases by +1. The mount&#8217;s spell resistance
equals the paladin&#8217;s class level + 5. </p>
    <p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Remove
Disease (Sp):</span> The epic paladin can use remove disease one
additional time per week for every three levels higher than 18th. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
paladin gains a bonus feat (selected from the list of epic paladin
feats) every three levels higher than 20th. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Paladin Bonus Feat List:</span>
Armor Skin, Devastating Critical, Epic Leadership, Epic Prowess, Epic
Reputation, Epic Toughness, Epic Weapon Focus, Great Smiting, Holy
Strike, Improved Aura of Courage, Improved Combat Casting, Improved
Spell Capacity, Legendary Commander, Legendary Rider, Overwhelming
Critical, Perfect Health, Permanent Emanation, Planar Turning, Positive
Energy Aura, Spectral Strike, Spontaneous Spell, Widen Aura of Courage.
    </p>
    <a id="table-the-epic-paladin"></a><span style={{
      "fontWeight": "bold"
    }}>Table: THE EPIC PALADIN</span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Paladin
Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>21st</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Remove disease</span> 6/week
          </td>
        </tr>
        <tr>
          <td>22nd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>23rd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td>24th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Remove disease </span>7/week
          </td>
        </tr>
        <tr className="odd-row">
          <td>25th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Smite
evil 6/day
          </td>
        </tr>
        <tr>
          <td>26th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>27th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Remove disease</span> 8/week
          </td>
        </tr>
        <tr>
          <td>28th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>29th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td className="last-row">30th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Remove disease</span>
9/week
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-ranger"></a><h3><a id="ranger"></a>EPIC RANGER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44172_C1_Ranger.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d8</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 6 + Int modifier</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells:</span> The ranger&#8217;s
caster level is equal to one-half his or her class level, as normal.
The ranger&#8217;s number of spells per day does not increase after 20th
level. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Favored Enemy (Ex):</span>
The epic (and his bonuses against all existing favored enemies go up by
+1) every five levels after 20th (25th, 30th, and so on).</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Animal companion:</span>&nbsp;
The epic ranger&#8217;s animal companion continues to increase in
power.&nbsp; The ranger&#8217;s effective druid level is half his or her
ranger level.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
ranger gains a bonus feat (selected from the list of epic ranger feats)
every three levels after 20th. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Ranger Bonus Feat List:</span>
Bane of Enemies, Blinding Speed, Combat Archery, Death of Enemies,
Distant Shot, Epic Endurance, Epic Prowess, Epic Speed, Epic Toughness,
Epic Weapon Focus, Fast Healing, Improved Combat Casting, Improved
Favored Enemy, Improved Manyshot, Improved Spell Capacity, Legendary
Climber, Legendary Rider, Legendary Tracker, Perfect Health, Perfect
Two-Weapon Fighting, Permanent Emanation, Spontaneous Spell, Storm of
Throws, Swarm of Arrows, Two-Weapon Rend, Uncanny Accuracy. </p>
    <a id="table-the-epic-ranger"></a><span style={{
      "fontWeight": "bold"
    }}>Table: THE EPIC RANGER
    </span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Paladin
Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>21st</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>22nd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>23rd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td>24th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>25th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>6th
favored enemy
          </td>
        </tr>
        <tr>
          <td>26th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>27th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>28th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>29th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td className="last-row">30th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>7th favored enemy
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-rogue"></a><h3><a id="rogue"></a>EPIC ROGUE</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44243_C1_rogue.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d6. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 8 + Int modifier.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Sneak Attack:</span> The epic
rogue&#8217;s sneak attack damage increases by +1d6 at every odd-numbered
level. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special Abilities:</span> The
rogue does not gain additional rogue special abilities after 19th
level, but can choose one of the rogue class special abilities
(crippling strike, defensive roll, improved evasion, opportunist, skill
mastery, or slippery mind) instead of a bonus feat. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Trap Sense (Ex):</span> The
epic rogue&#8217;s bonus increases by +1 every three levels higher than 18th.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
rogue gains a bonus feat (selected from the list of epic rogue feats)
every four levels after 20th. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Rogue Bonus Feat List:</span>
Blinding Speed, Combat Archery, Dexterous Fortitude, Dexterous Will,
Epic Dodge, Epic Reputation, Epic Skill Focus, Epic Speed, Improved
Combat Reflexes, Improved Sneak Attack, Legendary Climber, Lingering
Damage, Self-Concealment, Sneak Attack of Opportunity, Spellcasting
Harrier, Superior Initiative, Trap Sense, Uncanny Accuracy. The rogue
may choose a special rogue ability instead of a bonus feat. </p>
    <a id="table-the-epic-rogue"></a><p><span style={{
        "fontWeight": "bold"
      }}>Table: THE EPIC ROGUE</span></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Rogue
Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>21st</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Sneak
attack +11d6, Trap sense +7
          </td>
        </tr>
        <tr>
          <td>22nd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>23rd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Sneak
attack +12d6
          </td>
        </tr>
        <tr>
          <td>24th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Trap
sense +8, bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>25th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Sneak
attack +13d6
          </td>
        </tr>
        <tr>
          <td>26th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>27th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Sneak
attack +14d6, Trap sense +9
          </td>
        </tr>
        <tr>
          <td>28th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>29th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Sneak
attack +15d6
          </td>
        </tr>
        <tr>
          <td className="last-row">30th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Trap sense +10
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-sorcerer"></a><h3><a id="sorcerer"></a>EPIC SORCERER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44177_C1_sorcerer.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d4. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 2 + Int modifier. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells:</span> The sorcerer&#8217;s
caster level is equal to his or her class level. The sorcerer&#8217;s number
of spells per day does not increase after 20th level. The sorcerer does
not learn additional spells unless he or she selects the Spell
Knowledge feat. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Familiar:</span> The
sorcerer&#8217;s familiar continues to increase in power. Every two levels
beyond 20th the familiar&#8217;s natural armor and Intelligence each increase
by +1. The familiar&#8217;s spell resistance is equal to the master&#8217;s class
level + 5. At 21st level and again every ten levels higher than 21st,
the familiar gains the benefit of the Familiar Spell epic feat for a
spell of its master&#8217;s choice.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
sorcerer gains a bonus feat (selected from the list of epic sorcerer
bonus feats) every three levels after 20th. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Sorcerer Bonus Feat
List:</span> Augmented Alchemy, Automatic Quicken Spell, Automatic
Silent Spell, Automatic Still Spell, Energy Resistance, Enhance Spell,
Epic Spell Focus, Epic Spell Penetration, Epic Spellcasting, Familiar
Spell, Ignore Material Components, Improved Combat Casting, Improved
Heighten Spell, Improved Metamagic, Improved Spell Capacity, Intensify
Spell, Master Staff, Master Wand, Multispell, Permanent Emanation,
Spell Knowledge, Spell Stowaway, Spell Opportunity. </p>
    <a id="table-the-epic-sorcerer"></a><p><span style={{
        "fontWeight": "bold"
      }}>Table: THE EPIC SORCERER</span></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Sorcerer
Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>21st</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>22nd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>23rd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td>24th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>25th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>26th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>27th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>28th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>29th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td className="last-row">30th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-wizard"></a><h3><a id="wizard"></a>EPIC WIZARD</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44207_C1_wizard.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d4. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 2 + Int modifier. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells:</span> The wizard&#8217;s
caster level is equal to her class level. The wizard&#8217;s number of spells
per day does not increase after 20th level. Each time the wizard
achieves a new level, he or she learns two new spells of any spell
levels that he or she can cast (according to his or her new level). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Familiar:</span> The
wizards&#8217;s familiar continues to increase in power. Every two levels
beyond 20th the familiar&#8217;s natural armor and Intelligence each increase
by +1. The familiar&#8217;s spell resistance is equal to the master&#8217;s class
level + 5. At 21st level and again every ten levels higher than 21st,
the familiar gains the benefit of the Familiar Spell epic feat for a
spell of its master&#8217;s choice.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
wizard gains a bonus feat (selected from the list of epic wizard feats)
every three levels after 20th. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Wizard Bonus Feat List:</span>
Augmented Alchemy, Automatic Quicken Spell, Automatic Silent Spell,
Automatic Still Spell, Combat Casting, Craft Epic Magic Arms and Armor,
Craft Epic Rod, Craft Epic Staff, Craft Epic Wondrous Item, Efficient
Item Creation, Enhance Spell, Epic Spell Focus, Epic Spell Penetration,
Epic Spellcasting, Familiar Spell, Forge Epic Ring, Ignore Material
Components, Improved Combat Casting, Improved Heighten Spell, Improved
Metamagic, Improved Spell Capacity, Intensify Spell, Multispell,
Permanent Emanation, Scribe Epic Scroll, Spell Focus, Spell Knowledge,
Spell Mastery, Spell Penetration Spell Stowaway, Spell Opportunity,
Spontaneous Spell, Tenacious Magic. In addition to the feats on this
list, the wizard may select any item creation feat or metamagic feat
not listed here.</p>
    <a id="table-the-epic-wizard"></a><p><span style={{
        "fontWeight": "bold"
      }}>Table: THE EPIC WIZARD</span></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Wizard
Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>21st</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>22nd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>23rd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td>24th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>25th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>26th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>27th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>28th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>29th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td className="last-row">30th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
      </tbody>
    </table>
    <a id="familiars-at-epic-level"></a><h5>FAMILIARS AT EPIC LEVEL </h5>
    <p>Special abilities granted to a familiar continue to grow as
the level of its master increases beyond 20th, as shown on Table: Epic
Familiar Special Abilities. Even though the table shows advancement
only to 42nd level, that is not the limit. The patterns in the table
continue infinitely. Familiar special abilities gained at less than
20th level also continue to improve. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Familiar Spell:</span> The
familiar gains the benefit of the Familiar Spell epic feat for the
spell its master chooses. </p>
    <a id="table-epic-familiar-special-abilities"></a><p><span style={{
        "fontWeight": "bold"
      }}>Table: Epic Familiar Special
Abilities</span></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Master Class
Level</th>
          <th colSpan="1" style={{
            "width": "100px"
          }}>Natural Armor
          </th>
          <td style={{
            "width": "100px",
            "verticalAlign": "middle"
          }}><span style={{
              "fontWeight": "bold"
            }}>Int</span>
          </td>
          <td style={{
            "width": "150px",
            "verticalAlign": "middle"
          }}><span style={{
              "fontWeight": "bold"
            }}>Special</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>21st-22nd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+11
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>16
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "150px"
          }}>Familiar
spell
          </td>
        </tr>
        <tr>
          <td>23rd-24th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+12
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>17
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "150px"
          }}>
          </td>
        </tr>
        <tr className="odd-row">
          <td>25th-26th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+13
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>18
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "150px"
          }}>
          </td>
        </tr>
        <tr>
          <td>27th-28th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+14
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>19
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "150px"
          }}>
          </td>
        </tr>
        <tr className="odd-row">
          <td>29th-30th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+15
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>20
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "150px"
          }}>
          </td>
        </tr>
        <tr>
          <td>31st-32nd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+16
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>21
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "150px"
          }}>Familiar
spell
          </td>
        </tr>
        <tr className="odd-row">
          <td>33rd-34th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+17
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>22
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "150px"
          }}>
          </td>
        </tr>
        <tr>
          <td>35th-36th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+18
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>23
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "150px"
          }}>
          </td>
        </tr>
        <tr className="odd-row">
          <td>37th-38th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+19
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>24
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "150px"
          }}>
          </td>
        </tr>
        <tr>
          <td className="last-row">39th-40th
          </td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+20
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>25
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "150px"
          }}>
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>41st-42nd
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "100px"
          }}>+21
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "100px"
          }}>26
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "150px"
          }}>Familiar
spell
          </td>
        </tr>
      </tbody>
    </table>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-psionic-class-progression"></a><h4>EPIC PSIONIC CLASS PROGRESSION</h4>
    <p>Epic level psionic characters follow the same epic progression
as non-psionics epic characters, except as noted below.
    </p>
    <ul>
      <li>For manifesters, manifester level continues to increase
after 20th level. However, a manifester&#8217;s power points and powers known
at each level do not increase automatically after 20th level.</li>
      <li>The powers of psicrystals that are based on the creator&#8217;s
level continue to increase as their owner gains levels.</li>
      <li>Bonus feats for epic psionic classes can be chosen from
among any epic feats for which the character meets the prerequisites or
any psionic, metapsionic, or psionic item creation feat, as normal.</li>
    </ul>
    <p>
    </p>
    <a id="no-epic-bonus-power-progression"></a><h5>NO EPIC BONUS POWER PROGRESSION</h5>
    <p>Epic psionic characters do not gain additional bonus power
points above 20th. They may still gain more power points as their key
ability score increases.</p>
    <p>Psionic characters can take the Improved Manifestation feat,
which grants them additional power points each time they take the feat.
Epic manifesters can also take Epic Expanded Knowledge or Power
Knowledge, which give them access to additional powers, and Epic
Psionic Focus, which allows manifesters to use more than one
metapsionic power each time they expend their psionic focus.
    </p>
    <a id="epic-psion"></a><h3><a id="psion"></a>EPIC PSION</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44249_C1_psion.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d4. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 4 + Int modifier. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Powers:</span> The psion�s manifester
level is equal to his class level. The psion�s number of
power points per day does not increase after 20th level,
but the limit on power points spent on a metapsionic
power is 19 (though feats such as Improved
Manifestation extend this limit). Each time the psion
achieves a new level, he learns a new power of any
power level that he can manifest (according to his new
level) that is within his primary discipline. Psions
continue to gain bonus power points for high ability
scores.
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Psicrystal:</span> The epic
psion&#8217;s psicrystal, if he has one, continues to increase in power. At
every odd-numbered level higher than 20th (21st, 23rd, and so on) the
psicrystal&#8217;s natural armor bonus and Intelligence each increase by 1.
The psicrystal&#8217;s power resistance is equal to the master&#8217;s level + 5.
At 21st level and again every ten levels higher than 21st, the
psicrystal gains the benefit of the Psicrystal Power epic feat for a
power of its master&#8217;s choice.
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
psion gains a bonus feat every three levels higher than 20th (23rd,
26th, and so on). </p>
    <a id="table-the-epic-psion"></a><p><span style={{
        "fontWeight": "bold"
      }}>Table: THE EPIC PSION</span></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Psion
Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>21st</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>22nd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>23rd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td>24th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>25th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>26th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>27th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>28th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>29th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td className="last-row">30th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
      </tbody>
    </table>
    <a id="psicrystals-at-epic-level"></a><h5>PSICRYSTALS AT EPIC LEVEL </h5>
    <p>Special abilities granted to a psycrystal continue to grow as
the level of its master increases beyond 20th, as shown on Table: Epic
Psicrystal Special Abilities. Even though the table shows advancement
only to 42nd level, that is not the limit. The patterns in the table
continue infinitely. Psicrystal special abilities gained at less than
20th level also continue to improve. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Psicrystal Power:</span> The
psicrystal gains the benefit of the Psicrystal Power epic feat for the
power its master chooses.
    </p>
    <a id="table-epic-psicrystal-special-abilities"></a><span style={{
      "fontWeight": "bold"
    }}>Table: Epic Psicrystal Special
Abilities</span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Owner Level</th>
          <th colSpan="1" style={{
            "width": "125px"
          }}>Natural Armor Adj.
          </th>
          <td style={{
            "verticalAlign": "middle",
            "width": "75px"
          }}><span style={{
              "fontWeight": "bold"
            }}>Int Adj.</span>
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "125px"
          }}><span style={{
              "fontWeight": "bold"
            }}>Special</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>21st-22nd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+10
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+10
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "125px"
          }}>Psicrystal
Power
          </td>
        </tr>
        <tr>
          <td>23rd-24th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+11
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+11
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "125px"
          }}>
          </td>
        </tr>
        <tr className="odd-row">
          <td>25th-26th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+12
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+12
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "125px"
          }}>
          </td>
        </tr>
        <tr>
          <td>27th-28th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+13
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+13
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "125px"
          }}>
          </td>
        </tr>
        <tr className="odd-row">
          <td>29th-30th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+14
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+14
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "125px"
          }}>
          </td>
        </tr>
        <tr>
          <td>31st-32nd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+15
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+15
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "125px"
          }}>Psicrystal
Power
          </td>
        </tr>
        <tr className="odd-row">
          <td>33rd-34th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+16
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+16
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "125px"
          }}>
          </td>
        </tr>
        <tr>
          <td>35th-36th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+17
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+17
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "125px"
          }}>
          </td>
        </tr>
        <tr className="odd-row">
          <td>37th-38th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+18
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+18
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "125px"
          }}>
          </td>
        </tr>
        <tr>
          <td>39th-40th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "100px"
          }}>+19
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>+19
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "125px"
          }}>
          </td>
        </tr>
        <tr className="odd-row">
          <td>41st-42nd
          </td>
          <td>+20
          </td>
          <td>+20
          </td>
          <td>Psicrystal
Power
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-psychic-warrior"></a><h3><a id="psychic-warrior"></a>EPIC PSYCHIC WARRIOR</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44244_C1_psychicwarrior.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d8. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional
Level:</span> 2 + Int modifier. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Powers:</span> The psychic
warrior&#8217;s manifester level is equal to her class level. The psychic
warrior&#8217;s base power points do not increase after 20th level. The epic
psychic warrior gains one new power of any level that she can manifest
at every even-numbered level she attains higher than 20th (22nd, 24th,
26th, and so on).</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
psychic warrior gains a bonus feat (selected from the list of epic
psychic warrior bonus feats) at 21st level and every three levels
thereafter. 
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Psychic Warrior Bonus
Feat
List:</span> Armor Skin, Combat Archery, Damage Reduction, Devastating
Critical, Dire Charge, Distant Shot, Epic Endurance, Epic Leadership,
Epic Prowess, Epic Toughness, Epic Weapon Focus, Epic Weapon
Specialization, Exceptional Deflection, Improved Combat Reflexes,
Improved Stunning Fist, Improved Manifestation, Improved Whirlwind
Attack, Infinite Deflection, Instant Reload, Legendary Commander,
Legendary Rider, Legendary Wrestler, Over-whelming Critical, Penetrate
Damage Reduction, Perfect Two-Weapon Fighting, Reflect Arrows,
Spellcasting Harrier, Spell Knowledge, Storm of Throws, Superior
Initiative, Swarm of Arrows, Two-Weapon Rend, Uncanny Accuracy. In
addition to the feats on this list, the psychic warrior may select any
feat designated as a fighter or psychic warrior bonus feat but not
listed here. As noted in Epic Feats, psychic warriors learn psionic
versions of magic feats, as appropriate. </p>
    <a id="table-the-epic-psychic-warrior"></a><span style={{
      "fontWeight": "bold"
    }}>Table: THE EPIC PSYCHIC WARRIOR
    </span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "120px"
          }}>Psychic Warrior
Level</th>
          <th colSpan="1" style={{
            "width": "330px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>21st</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td>22nd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>23rd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>24th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>Bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>25th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>26th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>27th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td>28th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>29th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>-
          </td>
        </tr>
        <tr>
          <td className="last-row">30th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>Bonus feat
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-soulknife"></a><h3><a id="soulknife"></a>EPIC SOULKNIFE
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d10.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 4 + Int modifier.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Mind Blade:</span> At 25th
level and every five levels thereafter, the soulknife gains an
additional +1 enhancement bonus on attack rolls and damage rolls (+6 at
25th, +7 at 30th, and so on).</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Mind Blade Enhancement:</span>
The epic soulknife&#8217;s mind blade gains additional powers as the
soulknife gains epic levels. At 22nd level and every four levels
thereafter, the soulknife can improve the value of the weapon special
abilities on his mind blade by 1 (+5 equivalent bonus at 22nd, +6 at
26th, and so on). As noted in the soulknife class description,
reassigning qualities still takes 8 hours of concentration.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Psychic Strike:</span> The
epic soulknife&#8217;s psychic strike progression continues past 20th level,
continuing to grant an additional die (d8) of damage every four levels
higher than 19th (+6d8 at 23rd, +7d8 at 27th, and so on).</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
soulknife gains a bonus psionic feat every three levels higher than
20th (23rd, 26th, and so on).</p>
    <a id="table-the-epic-soulknife"></a><p><span style={{
        "fontWeight": "bold"
      }}>Table: THE EPIC SOULKNIFE
      </span></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "120px"
          }}>Soulknife Level</th>
          <th colSpan="1" style={{
            "width": "330px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>21st</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>22nd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>Mind
blade enhancement +5
          </td>
        </tr>
        <tr className="odd-row">
          <td>23rd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td>24th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>25th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>+6 <span style={{
              "fontStyle": "italic"
            }}>mind blade</span>
          </td>
        </tr>
        <tr>
          <td>26th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>Mind
blade enhancement +6, bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>27th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>28th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>29th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>Bonus feat
          </td>
        </tr>
        <tr>
          <td className="last-row">30th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>+7 <span style={{
              "fontStyle": "italic"
            }}>mind
blade, </span>Mind
blade enhancement +7 </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-wilder"></a><h3><a id="wilder"></a>EPIC WILDER
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d6.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 4 + Int modifier.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Powers:</span> The epic
wilder&#8217;s manifester level is equal to her class level. The wilder&#8217;s
power points and powers known do not increase after 20th level (except
through the use of epic feats).</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Wild Surge:</span> The epic
wilder&#8217;s wild surge continues to increase after 20th level. At 23rd
level and every four levels higher than 23rd, the wild surge continues
to gain an additional +1 boost to manifester level when used (+7 at
23rd, +8 at 27th, and so on).</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Surging Euphoria:</span> The
wilder&#8217;s surging euphoria progression continues past 20th level. At
28th level and every eight levels higher than 28th, the morale bonus on
her attack rolls, damage rolls, and saving throws increases by 1 (+4 at
28th, +5 at 36th, and so on).</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Volatile Mind:</span> The
epic wilder&#8217;s volatile mind ability continues to increase. At 21st
level and every four levels higher than 21st, the penalty assessed
against telepathy powers manifested on the wilder is increased by 1
power point (5 points at 21st, 6 points at 25th, and so on).</p>
    <a id="table-the-epic-wilder"></a><p><span style={{
        "fontWeight": "bold"
      }}>Table: THE EPIC WILDER
      </span></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "120px"
          }}>Wilder Level</th>
          <th colSpan="1" style={{
            "width": "330px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>21st</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>Volatile
mind (5 power points)
          </td>
        </tr>
        <tr>
          <td>22nd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>23rd</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>Wild
surge +7
          </td>
        </tr>
        <tr>
          <td>24th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>25th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>Volatile
mind (6 power points)</td>
        </tr>
        <tr>
          <td>26th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>27th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>Wild
surge +8
          </td>
        </tr>
        <tr>
          <td>28th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>Surging
euphoria +4
          </td>
        </tr>
        <tr className="odd-row">
          <td>29th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>Volatile
mind (7 power points)</td>
        </tr>
        <tr>
          <td className="last-row">30th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "330px"
          }}>-
          </td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      